import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "time-clock-integrations"
    }}>{`Time clock integrations`}</h1>
    <p>{`Punch Clock is Planday’s time clock feature that supports keeping track of employees' punctuality as well as approving hours for payroll. For a quick intro you can have a look at our `}<a parentName="p" {...{
        "href": "https://help.planday.com/en/articles/3704338-setup-punch-clock"
      }}>{`Setup Punch Clock`}</a>{` Help Centre article.`}</p>
    <p>{`Use the APIs to read Punch Clock data or create new Punch Clock entries on an employees’ shift in Planday. Suggested scopes for integrating with the Punch Clock are:`}</p>
    <ul>
      <li parentName="ul">{`Department read`}</li>
      <li parentName="ul">{`Employee read`}</li>
      <li parentName="ul">{`Shifts read`}</li>
      <li parentName="ul">{`Punch Clock read/create`}</li>
    </ul>
    <h3 {...{
      "id": "get-punch-clock-records"
    }}>{`Get Punch Clock records`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`GET /punchclock/v{version}/punchclockshifts`}</inlineCode>{` endpoint returns all the Punch Clock records for a specified period.
On the record you will see the Punch Clock entry’s start and end time as well as the shift’s start and end time.`}</p>
    <p>{`In Planday, you can approve Punch Clock entries, and also set up rounding rules that will trigger changes to the shift’s start and end time at the time of approval. In this respect, you might want to check if the Punch Clock entry is approved in the `}<inlineCode parentName="p">{`isApproved`}</inlineCode>{` property.
The records include a `}<inlineCode parentName="p">{`punchClockShiftId`}</inlineCode>{`, which you will have to use to find the breaks from Punch Clock (see below).`}</p>
    <p>{`You can also use the `}<inlineCode parentName="p">{`shiftId`}</inlineCode>{` to call the `}<inlineCode parentName="p">{`GET /scheduling/v{version}/shifts`}</inlineCode>{` endpoint, if you need more information about the shift to which the Punch Clock entry is related.`}</p>
    <h4 {...{
      "id": "get-punch-clock-break-records"
    }}>{`Get Punch Clock break records`}</h4>
    <p>{`To get the records for breaks from the Punch Clock you can use the `}<inlineCode parentName="p">{`GET /punchclock/v{version}/punchclockshifts/{punchClockShiftId}/breaks`}</inlineCode>{` endpoint.`}</p>
    <p>{`To make this query you need to specify the `}<inlineCode parentName="p">{`punchClockShiftId`}</inlineCode>{` from the Punch Clock entry.`}</p>
    <h4 {...{
      "id": "breaks-not-related-to-punch-clock"
    }}>{`Breaks not related to Punch Clock`}</h4>
    <p>{`Please be aware that this is only for breaks from the Punch Clock. To find details about the manual breaks or breaks from break rules you have to use the Payroll API. `}</p>
    <h3 {...{
      "id": "add-punch-clock-entries"
    }}>{`Add Punch Clock entries`}</h3>
    <p>{`There are two paths for creating Punch Clock entries through the API. Regardless of your selected approach, it is possible to specify the start time and end time, or leave it blank. If blank, the endpoint will use the time of the request.`}</p>
    <h4 {...{
      "id": "employee-punch-in---out"
    }}>{`Employee punch in  / out`}</h4>
    <p>{`With the employee punch in / out endpoints you only need to specify the `}<inlineCode parentName="p">{`employeeId`}</inlineCode>{` in your API calls and therefore you don’t have to query other endpoints to find the `}<inlineCode parentName="p">{`shiftId`}</inlineCode>{` of the shift which the employee should punch in on. Please note that if an employee has more than one upcoming shift on the given day, the endpoint will return an error. The error will include the `}<inlineCode parentName="p">{`shiftId`}</inlineCode>{` of the shifts the employee is assigned to, which makes it possible for you to display the shifts and let the user select which shift to add the Punch Clock entry to.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Employee punch in`}</strong>{` :
Use `}<inlineCode parentName="p">{`POST /punchclock/v{version}/punchclockshifts/employee/{employeeId}/punchin`}</inlineCode>{` to punch in with a specified employeeId to create a Punch Clock entry with a specified employeeId. In other words, this endpoint allows you to clock in an employee on their only upcoming or ongoing shift on a given day. The entry is created at the time of the request (if `}<inlineCode parentName="p">{`startDateTime`}</inlineCode>{` is not included) on the upcoming or ongoing shift for this employee. If the employee is assigned to more than one upcoming shift on a given date, `}<inlineCode parentName="p">{`shiftId`}</inlineCode>{` needs to be specified. If successful, the response will include the `}<inlineCode parentName="p">{`PunchClockShiftId`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Employee punch out`}</strong>{`
Use `}<inlineCode parentName="p">{`PUT /punchclock/v{version}/punchclockshifts/employee/{employeeId}/punchout`}</inlineCode>{` to punch out with a specified employeeId to update an existing Punch Clock entry, i.e. a Punch Clock entry that has a `}<inlineCode parentName="p">{`startDateTime`}</inlineCode>{`. In other words, this enables you to clock out an employee at the end of their ongoing shift. The entry is updated at time the of the request (if `}<inlineCode parentName="p">{`endDateTime`}</inlineCode>{` is not included).`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "create-a-punch-inout-entry"
    }}>{`Create a punch-in/out entry`}</h4>
    <p>{` These endpoints require you to specify shift details to ensure the Punch Clock records are registered on the right shift, regardless of the number of shifts the employee is assigned to on the given date. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Create a Punch-In entry`}</strong>{` : The `}<inlineCode parentName="p">{`POST /punchclock/v{version}/punchclockshifts`}</inlineCode>{` endpoint requires first fetching the available shifts for an employee to punch in on. Do this by calling the `}<inlineCode parentName="p">{`GET /punchclock/v{version}/employeeshifts/today`}</inlineCode>{` endpoint to fetch today’s shifts for an employee. You can also use the `}<inlineCode parentName="p">{`GET /scheduling/v{version}/shifts`}</inlineCode>{` endpoint in the Scheduling API, which supports more query parameters to find the right shift.
When you have created a Punch Clock entry, it returns a `}<inlineCode parentName="p">{`punchClockShiftId`}</inlineCode>{`, which you’ll need for punching out.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Create a Punch-Out entry`}</strong>{` : To add a punch out entry you have to use the `}<inlineCode parentName="p">{`PUT /punchclock/v{version}/punchclockshifts`}</inlineCode>{` and include the `}<inlineCode parentName="p">{`punchClockShiftId`}</inlineCode>{` from the punch in entry.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      